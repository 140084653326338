import React from "react";
import "./footer.scss";
import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <div className="footer p-3">
      <Container className="">
        <Row>
          <Col lg="4" className="mb-4">
            <div className="mbc text-center footer-img-wrapper ">
              <img
                className="img-fluid footer-logo "
                src={require("../../assets/images/log.png").default}
                alt=""
                rel="noreferrer"
                id="secret_cat"
              />
            </div>
          </Col>
          <Col lg="4" className="mb-4">
            <div className="mbc social-logo h-100 d-flex justify-content-around align-items-center text-center">
              <a
                href="https://discord.gg/cPMg89U9"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ height: "40px" }}
                  className="img-fluid  "
                  src={require("../../assets/images/Discord.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>
              <a
                href="https://twitter.com/cowboykitties"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ height: "40px" }}
                  className="img-fluid  "
                  src={require("../../assets/images/Twitter.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>
              {/*<a
                rel="noreferrer"
                target="_blank"
                id="plusMaticWallet"
              >
                <img
                  style={{ height: "40px" }}
                  className="img-fluid  "
                  src={require("../../assets/images/Matic.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>*/}
              {/* <a href="#" rel="noreferrer" target="_blank">
                <img
                  style={{ height: "45px" }}
                  className="img-fluid  "
                  src={require("../../assets/images/Opensea.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a> */}
            </div>
          </Col>
          <Col lg="4" className="mb-4">
            <div className="mbc h-100   d-flex justify-content-center align-items-center">
              <div className="">
                <span className="font-weight-bold">
                  © {new Date().getFullYear()} Cowboykitty. All rights reserved.
                </span>
                <br />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
