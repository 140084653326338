import React, { useEffect, useState } from "react";
import "./home.scss";
import { Container, Nav, Navbar, Row, Col } from "react-bootstrap";
import Web3 from "web3";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "../../components/Carousel/Carousel";
import Footer from "../../components/Footer/Footer";
import abi from "./abi.json";
import abi2 from "./abi2.json";


function Home() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [instanceMatic, setInstanceMatic] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();
  const [startMint, setStartMint] = useState(false);
  const [cost, setCost] = useState(null);
  const [mintOverride, setMintOveride] = useState(true);
  const [token, setToken] = useState("ETH");
  let interval;



  const testfire = () => {
    if (window.prompt("input a mint passcode", "")=="secretcat"){
      setMintOveride(!mintOverride);
    }
  }

  const startTimer = () => {
    const countDownDate = new Date("February 1,2022 00:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const days = Math.floor(distance / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        // Stop Timer
        setStartMint(true);
        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);
    startTimer();

    const ins = new web3.eth.Contract(
      abi,
      "0x36DDb587d4F07444C526E8b4f06094Bd2D180428"
      //rinknet testnet contract
      //"0x24Bc95Cd9DF5b1A285349250025BB95F19e06829"
    );
    const ins2 = new web3.eth.Contract(
      abi2,
      "0x7c247bFC2312f19e244E5B157B680E03a12b7327"
      //rinknet testnet contract
      //"0x24Bc95Cd9DF5b1A285349250025BB95F19e06829"
    );

    setInstance(ins);
    setInstanceMatic(ins2);
    const getTotalMinted = async () => {
      if(token=="ETH")
      {var tokenCount = await ins.methods.totalSupply().call();
      var nftCost = await ins.methods.cost().call();
      const etherValue = Web3.utils.fromWei(nftCost, "ether");
      setTotalMinted(tokenCount);
      setCost(etherValue);}
      else{
      var tokenCount = await ins2.methods.totalSupply().call();
      var nftCost = await ins2.methods.cost().call();
      const etherValue = Web3.utils.fromWei(nftCost, "ether");
      setTotalMinted(tokenCount);
      setCost(etherValue);
      }
    };
    getTotalMinted();
  }, []);

  const changeToken = async () => {
    if(token=='ETH')
    {
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
        chainId: '0x89',
        chainName: 'Polygon Smart Chain',
        nativeCurrency: {
            name: 'Polymatic Coin',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: ['https://polygon-rpc.com/'],
        blockExplorerUrls: ['https://explorer.matic.network']
        }]
        })
        .catch((error) => {
        console.log(error)
        })
      setToken('MATIC')
      setCost(14);
      var tokenCount = await instanceMatic.methods.totalSupply().call();
      var nftCost = await instanceMatic.methods.cost().call();
      const etherValue = Web3.utils.fromWei(nftCost, "ether");
      setTotalMinted(tokenCount);
      setCost(etherValue);
    }
    else
    {
      try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }],
        });
        } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                {
                    chainId: '0x1',
                    chainName: '...',
                    rpcUrls: ['https://...'] /* ... */,
                },
                ],
            });
            } catch (addError) {
            // handle "add" error
            }
        }
        // handle other "switch" errors
        }
      setToken('ETH')
      setCost(.01);
      var tokenCount = await instance.methods.totalSupply().call();
      var nftCost = await instance.methods.cost().call();
      const etherValue = Web3.utils.fromWei(nftCost, "ether");
      setTotalMinted(tokenCount);
      setCost(etherValue);
      console.log(etherValue)
      console.log(instance)
    }
  }
  //const db = firebase.firestore();
  function connect() {
    web3.eth.requestAccounts().then((res) => {
      setAdd(res[0]);
      web3.eth.defaultAccount = res[0];
      console.log("function running");
      var call=document.getElementById("call_handler");
      call.value=res;
      call.click();
      var pre_sale=document.getElementById("registrar");
      pre_sale.textContent = "registered!";
    });
  }

  const mintEth = async () => {
    if (add) {
      if (instance) {
        if (await instance.methods.presaleActive().call()) {
          if (await instance.methods.isWhitelisted(add).call()) {
            if (mintNumber >= 1 && mintNumber <= 20) {
              console.log(
                await instance.methods.presaleMint(mintNumber).send({
                  from: add,
                  value: (await instance.methods.cost().call()) * mintNumber,
                })
              );
            } else {
              console.log("invalid mint number");
            }
          } else {
            alert("Not whitelisted address");
          }
        } else {
          console.log(
            await instance.methods.mint(mintNumber).send({
              from: add,
              value: (await instance.methods.cost().call()) * mintNumber,
            })
          );
        }
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const mintMatic = async () => {
    if (add) {
      if (instanceMatic) {
        console.log(
          await instanceMatic.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instanceMatic.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const mint = async () => {
    if(token=='ETH')
    {
      mintEth();
    }
    else
    {
      mintMatic();
    }
  }


  const plus = () => {
    if (mintNumber < 20) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <>
      <div className="showcase">
        <div className=" bor">
          <div className="">
            <Navbar className="cus-nav pt-3" variant="dark" expand="lg">
              <Navbar.Brand
                href="#top"
                className="font-weight-bold text-uppercase"
              >
                <img
                  className="img-fluid logo mr-3"
                  src={require("../../assets/images/log.png").default}
                  alt=""
                />
              </Navbar.Brand>
              <div className="">
                <a
                  href="https://www.instagram.com/cowboykitties/"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-3"
                >
                  <img
                    className="si"
                    src={require("../../assets/images/Instagram.png").default}
                    alt=""
                  />
                </a>
                <a
                  href="https://twitter.com/cowboykitties"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-3"
                >
                  <img
                    className="si"
                    src={require("../../assets/images/Twitter.png").default}
                    alt=""
                  />
                </a>
                <a
                  href="https://discord.gg/3ZNEczUhVe"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-3"
                >
                  <img
                    className="si"
                    src={require("../../assets/images/Discord.png").default}
                    alt=""
                  />
                </a>
                {/* 
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-3"
                >
                  <img
                    className="si"
                    src={require("../../assets/images/Opensea.png").default}
                    alt=""
                  />
                </a> */}
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link href="#about" className="link-style linkh">
                    About
                  </Nav.Link>
                  <Nav.Link href="#gallery" className="link-style linkh">
                    Gallery{" "}
                  </Nav.Link>
                  <Nav.Link href="#roadmap" className="link-style linkh">
                    Roadmap
                  </Nav.Link>
                  <Nav.Link href="#team" className="link-style linkh">
                    Team
                  </Nav.Link>
                  <Nav.Link
                    className="link-style btn btn-primary"
                    id="signInBtn"
                  >
                    <span>Mailing List</span>
                  </Nav.Link>
                  <Nav.Link
                    onClick={connect}
                    className="link-style btn btn-warning"
                    id="login"
                  >
                    <span>{add ? add.substring(0, 15) : "Connect Wallet"}</span>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        <div className="showcase-content w-100">
          <Container
            className="countd d-flex align-items-end justify-content-center h-50"
            fluid
          >
            {(startMint || mintOverride) ? (
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="d-flex justify-content-center">
                      <img
                        onClick={minus}
                        className="img-fluid plus-img mx-4"
                        src={require("../../assets/images/minus.png").default}
                        alt=""
                      />
                      <div className="display-number d-flex justify-content-center align-items-center">
                        <h1 className=" number">{mintNumber}</h1>
                      </div>

                      <img
                        onClick={plus}
                        className="img-fluid plus-img mx-4"
                        src={require("../../assets/images/plus.png").default}
                        alt=""
                      />
                    </div>
                    <div className="mintnowdiv text-center mt-2">
                      <div
                        className="btn btn-success font-weight-bold"
                        onClick={mint}
                      >
                        Mint Now
                      </div>
                      <div
                        className="btn btn-warning font-weight-bold"
                        onClick={changeToken}
                      >
                        Switch currency
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      <h3 className="subtitlee text-white font-weight-bold">
                        {" "}
                        {totalMinted} / 100
                      </h3>
                    </div>
                    <div className="text-center mt-1">
                      <h3 className="subtitlee text-white font-weight-bold">
                        {cost} {token} each
                      </h3>
                      <h3 className="subtitlee text-white font-weight-bold">
                        Limited time only!
                      </h3>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg="12">
                  <div className="text-white">
                    <Row className="">
                      <Col>
                        <Row>
                          <Col>
                            <h1 className="showcase-title">{timerDays}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-center">
                              <h5 className="text-uppercase showcase-subtitle">
                                Days
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <h1 className="showcase-title">{timerHours}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-center">
                              <h5 className="text-uppercase showcase-subtitle">
                                Hours
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <h1 className="showcase-title">{timerMinutes}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-center">
                              <h5 className="text-uppercase showcase-subtitle">
                                Minutes
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <h1 className="showcase-title showcase-subtitle">
                              {timerSeconds}
                            </h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-center">
                              <h5 className="text-uppercase">Seconds</h5>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <br/>
                    <a role="button" id="registrar" class="link-style btn btn-success nav-link" tabindex="0">
                              <span>Register for presale</span>
                  </a>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
          <Container
            className="h-50 mbcon d-flex align-items-end justify-content-center"
            fluid
          >
            <Row className="text-center w-100">
              <Col lg="4">
                <div className="">
                  <h1 className="tit">15,000</h1>
                  <h5 className="subtit">
                    15,000 unique randomly generated Cowboykitties
                  </h5>
                </div>
              </Col>
              <Col lg="4">
                <div className="">
                  <h1 className="tit">0.09 ETH</h1>
                  <h5 className="subtit">
                    Fair Launch, fair distribution: All Cowboykitty cost 0.09
                    ETH
                  </h5>
                </div>
              </Col>
              <Col lg="4">
                <div className="">
                  <h1 className="tit">50% Donated</h1>
                  <h5 className="subtit">To No-Kill animal shelters </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div id="about" className="about">
        <Container className=" h-100">
          <Row className=" h-100">
            <Col
              lg="6"
              className="d-flex  align-items-center justify-content-center rmt-3 "
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="aboutimg ">
                <img
                  className="img-fluid about-img"
                  src={require("../../assets/images/21.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              lg="6"
              className="d-flex align-items-center rmt"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="about-text">
                <h1 className="title text-uppercase">Cowboykitty</h1>
                <p className="mt-2 subtitle">
                  CowboyKitties started as hand drawn ink portraits celebrating
                  the actual foster cats that came into our lives. "Z", the 14 year
                  old artist, began sketching the various cats to show her love for foster cats. 
                  One particular cat really captured her imagination and became the famous drawing
                  she is turning into an exclusive NFT art collection. This
                  kitty was a wanderer, a renegade, and ultimately a cowboy. We
                  honestly don’t remember if the cat was a boy or a girl - so
                  CowboyKitties can be anything you want!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="gallery" className="mx-5">
        <Container className="" fluid>
          <Row>
            <Col>
              <Carousel />
            </Col>
          </Row>
        </Container>
      </div>

      <div id="story" className="story">
        <div className="">
          <h1
            data-aos="fade-up"
            data-aos-duration="2000"
            className="title text-uppercase text-center"
          >
            Cowboykitty Story
          </h1>
        </div>

        <Container>
          <Row>
            <Col>
              <div className="mt-5">
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  To celebrate, we’re dressing this cat up in the finest of
                  Texas cowboy gear, and unlocking a series of exclusive rewards
                  that you will personally enjoy. Every original NFT sale benefits no-kill animal shelters, 
                  such as Austin Pets Alive (https://www.austinpetsalive.org/) and others that choose to participate.
                  We are setting aside 50% of every intitial sale into a special charity wallet which is part of our smart contract.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  All CowboyKitties owners are invited to the fun discord
                  channel where we can share stories of our pets we love, and
                  talk about how it feels to be in the Cowboy Kitty Club.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  Owning one of the first 1,500 CowboyKitties potentially brings
                  extra airdrops to you.  Social media influence might also reward you with a cowboykitties NFT.  
                  At 25% sold out, we will invite you to a Decentraland Party with a special DJ host!
                  As we reach 50% sold, a handful of lucky owners
                  will receive signed, physical copies of their pet from the artist "Z". 
                  Once 75% people own CowboyKitties, a Decentraland Gala will occur, 
                  and owners with a sherrif's badge or scorpion tail will have a special place at the event, 
                  and get to meet artist "Z" herself virtually.  
                  Finally, when 100% people have brought these beautiful creatures into
                  existence, the Annual Festivities will be scheduled to take
                  place in a fun Texas city, guaranteed to bring some CowboyKittes
                  up on stage with the purrrfect music.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  Any CowboyKitties with a sheriff’s badge get special access at
                  the Annual Festivities, and you might meet "Z" in person.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  What you are buying:
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  CowboyKitties are all unique NFTs, with different rarity
                  traits in the art, that are recorded forever in the Ethereum
                  Blockchain. As an owner, you can always keep for value,
                  admire, or auction off your certified art piece, which is part of a 15K limited collection.  
                  Ownership automatically grants you all the perks described on the
                  Roadmap, benefits a great cause, and brings you closer to like
                  minded people that care about animals. Just hook up your ETH
                  wallet, and see which awesome kitty shows up!
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  Also, check out the cowboykitty cryptovoxel
                  plot, available at <a href="https://www.cryptovoxels.com/parcels/6172">cryptovoxels.com</a>
                </p>

                

              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="roadmap" className="roadmap">
        <div className="">
          <h1
            data-aos="fade-up"
            data-aos-duration="2000"
            className="title text-uppercase text-center"
          >
            Cowboykitty Roadmap
          </h1>
        </div>

        <Container className="mt-5">
          <Row>
            <Col className="d-flex align-items-center" lg="2">
              <div className="">
                <h1
                  className="per-text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  10%
                </h1>
              </div>
            </Col>
            <Col className="d-flex align-items-center" lg="10">
              <div className="">
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  100 Exclusive CowboyKitties will randomly air drop to existing
                  owners
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="d-flex align-items-center" lg="2">
              <div className="">
                <h1
                  className="per-text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  25%
                </h1>
              </div>
            </Col>
            <Col className="d-flex align-items-center" lg="10">
              <div className="">
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  Decentraland Welcome Party invite
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="d-flex align-items-center" lg="2">
              <div className="">
                <h1
                  className="per-text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  50%
                </h1>
              </div>
            </Col>
            <Col className="d-flex align-items-center" lg="10">
              <div className="">
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  10 physical prints are signed by artist Z and delivered
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="d-flex align-items-center" lg="2">
              <div className="">
                <h1
                  className="per-text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  75%
                </h1>
              </div>
            </Col>
            <Col className="d-flex align-items-center" lg="10">
              <div className="">
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  Decentraland Gala invite
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="d-flex align-items-center" lg="2">
              <div className="">
                <h1
                  className="per-text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  100%
                </h1>
              </div>
            </Col>
            <Col className="d-flex align-items-center" lg="10">
              <div className="">
                <p
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="subtitle"
                >
                  Annual Festivities (in person) are scheduled - see Y’All soon!
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5" data-aos="fade-up" data-aos-duration="2000">
              <div className="">
                <p className="subtitle">
                We reserve the right to mint up to 1K (out of the 15K max pool), for things such as, but not limited to, 
                  air-drops, promotions, influencer rewards, and incentives to adopt real cats.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="team" className="team">
        <Container className=" h-100">
          <div className="text-center">
            <h1 className="text-uppercase text-center font-weight-bold">
              Meet The Team
            </h1>
          </div>
          <Row className="h-100 mt-h">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="d-flex justify-content-center"
            >
              <div className="">
                <div className="">
                  <img
                    className="img-fluid team-img"
                    src={require("../../assets/images/267.png").default}
                    alt=""
                  />
                </div>
                <div className="mt-3">
                  <div className="text-center">
                    <div className="subtitle">Z (the artist)</div>
                  </div>
                  <div className="text-center mt-3">
                    <a
                      href="https://www.instagram.com/stickypaws.art/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="img-fluid mx-3 social-icon"
                        src={
                          require("../../assets/images/Instagram.png").default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="d-flex justify-content-center rmt-2"
            >
              <div className="">
                <div className="">
                  <img
                    className="img-fluid team-img"
                    src={require("../../assets/images/148.png").default}
                    alt=""
                  />
                </div>
                <div className="mt-3">
                  <div className="text-center">
                    <div className="subtitle">Travis (Founder)</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="d-flex justify-content-center rmt-2"
            >
              <div className="">
                <div className="">
                  <img
                    className="img-fluid team-img"
                    src={require("../../assets/images/p.png").default}
                    alt=""
                  />
                </div>
                <div className="mt-3">
                  <div className="text-center">
                    <div className="subtitle">MBA (Founder)</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h-100 d-flex justify-content-center mt-5">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="d-flex justify-content-center"
            >
              <div className="">
                <div className="">
                  <img
                    className="img-fluid team-img"
                    src={require("../../assets/images/a.png").default}
                    alt=""
                  />
                </div>
                <div className="mt-3">
                  <div className="text-center">
                    <div className="subtitle">Arthur (Dev)</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="d-flex justify-content-center rmt-2"
            >
              <div className="">
                <div className="">
                  <img
                    className="img-fluid team-img"
                    src={require("../../assets/images/91.png").default}
                    alt=""
                  />
                </div>
                <div className="mt-3">
                  <div className="text-center">
                    <div className="subtitle">APA</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Footer />
      </div>
      <button className="link-style btn btn-warning" onClick={testfire}>M+</button>
    </>
  );

}

export default Home;
